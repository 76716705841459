.element-o {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100vw !important;
  }

.element-o .horsepic {
    width: 100vw;
    position: 50% 50%;
    height: 100vh;
    filter: brightness(.75) contrast(120%);
    object-fit: cover;
    transform: scaleX(1);
}

.element-o .horsepic:after {
    position: absolute;
    content:"";
    height:100%;
    width:100%;
    top:0;
    left:0;
    background: linear-gradient(to bottom, rgba(255,0,0,0) 0%,rgba(255,0,0,0.65) 100%);
}

.element-o .mobile-horsepic {
    width: 100vw;
    position: 50% 50%;
    height: 100vh;
    filter: brightness(.65);
    object-fit: cover;
    transform: scaleX(1);
}

.element-o .h-1 {
    color: #ffffff;
    font-family: "Libre Caslon Text", Helvetica;
    font-size: 72px;
    line-height: 80px;
    font-weight: 400;
    letter-spacing: 0;
    margin-top: 0px;
    position: relative;
    width: fit-content;
  }

  .element-o .p {
    color: #ffffff;
    font-family: "Abhaya Libre", Helvetica;
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
    height: 28px;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: 545px;
  }
  
  .element-o .mobile-p {
    color: #ffffff;
    font-family: "Abhaya Libre", Helvetica;
    font-size: 25px;
    font-weight: 400;
    height: 88px;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: calc(100vw - 50px);
  }
  
  .element-o .join-the-waitlist {
    height: 40px;
    width: 260px;
    position: relative;
  }

  .element-o .landing-2 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 24px;
    left: 60px;
    padding: 0;
    position: absolute;
    top: 55vh;
    width: 656px;
  }
  
  .element-o .mobile-landing-2 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 20px;
    left: 37px;
    padding: 15px 0px;
    position: absolute;
    top: 257px;
    width: 300px;
    background: 'blue'
  }

  
  .element-o .logo {
    left: 35px;
    display: 'inline-flex';
    gap: var(--space-xxs, 8px);
    flex-direction: 'column';
    align-items: 'center';
    position: absolute;
    top: 15px;
    height: 36px;
    width: 66px;
    
  }
  
  .element-o .mobilelogo {
    left: 35%;
    position: absolute;
    top: 45px;
    width: 30%;
  }
  
  .element-o .text-wrapper {
    color: #ffffff;
    font-family: "Cinzel", Helvetica;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: center;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 25px;
    left: 110px;
  }
  
  .element-o .investnowtop {
    position: absolute;
    top: 20px;
    right: 40px;
  }
  
  .element-o .contactustop {
    position: absolute;
    top: 20px;
    right: 235px;
  }

  .each-slide-effect > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
  }

  .each-slide-effect-mobile > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: calc(40vh - 100px);
  }
  
  .each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }

  .each-slide-effect-mobile span {
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }