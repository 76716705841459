.element-c {
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100% !important;
  }

.element-c .horsepic {
    width: 100vw;
    position: 50% 50%;
    height: max(100vh, 882px);
    filter: brightness(.75) contrast(120%);
    object-fit: cover;
    transform: scaleX(1);
}

.element-c .horsepic:after {
    position: absolute;
    content:"";
    height:100%;
    width:100%;
    top:0;
    left:0;
    background: linear-gradient(to bottom, rgba(255,0,0,0) 0%,rgba(255,0,0,0.65) 100%);
}

.element-c .mobile-horsepic {
    width: 100vw;
    position: 50% 50%;
    height: 100vh;
    filter: brightness(.65);
    object-fit: cover;
    transform: scaleX(1);
}

.element-c .h-1 {
    color: black;
    font-family: "Libre Caslon Text", Helvetica;
    font-size: 72px;
    line-height: 80px;
    font-weight: 400;
    letter-spacing: 0;
    margin-top: 0px;
    position: relative;
    width: fit-content;
  }

  .element-c .p {
    color: black;
    font-family: "Abhaya Libre", Helvetica;
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
    height: 28px;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: 545px;
  }
  
  .element-c .mobile-p {
    color: #ffffff;
    font-family: "Abhaya Libre", Helvetica;
    font-size: 25px;
    font-weight: 400;
    height: 88px;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: calc(100vw - 50px);
  }
  
  .element-c .join-the-waitlist {
    height: 40px;
    width: 260px;
    position: relative;
  }

  .element-c .landing-2 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 24px;
    left: 60px;
    padding: 0;
    position: absolute;
    top: 617px;
    width: 656px;
  }
  
  .element-c .mobile-landing-2 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 20px;
    left: 37px;
    padding: 15px 0px;
    position: absolute;
    top: 287px;
    width: calc(100vw - 37px);
  }

  
  .element-c .logo {
    left: 35px;
    display: 'inline-flex';
    gap: var(--space-xxs, 8px);
    flex-direction: 'column';
    align-items: 'center';
    position: absolute;
    top: 15px;
    height: 36px;
    width: 66px;
    
  }
  
  .element-c .mobilelogo {
    left: 30%;
    position: absolute;
    top: 45px;
    width: 40%;
  }
  
  .element-c .text-wrapper {
    color: black;
    font-family: "Cinzel", Helvetica;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: center;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 25px;
    left: 110px;
  }
  
  .element-c .investnowtop {
    position: absolute;
    top: 20px;
    right: 40px;
  }
  
  .element-c .contactustop {
    position: absolute;
    top: 20px;
    right: 235px;
  }

  .each-slide-effect > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 592px;
    max-width: 1200px;
  }
  
  .each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }