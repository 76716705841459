body {
    margin: 0;
    font-family: 'Allerta';
    /* color: white !important; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: 'Allerta';
}

input[type="file"] {
    display: none;
}

::-webkit-scrollbar {
    display: none;
}

button.mapboxgl-popup-close-button {
    height: 30px !important;
    width: 30px !important;
    font-size: 24px !important;
    padding: 0 !important;
    line-height: 1 !important;
    border-top-right-radius: 8px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
    supported by Chrome and Opera */
}

.react-tel-input .form-control {
    font-family: 'Allerta' !important;
    width: 244px !important;
    height: 37px !important;
    background-color: transparent !important;
    color: white !important;
    border: none !important;
    border-radius: 8px !important;
    margin: 10px !important;
    font-size: 14px !important;
    outline: none !important;
}

.react-tel-input .flag-dropdown {
    background-color: transparent !important;
    border: none !important;
    border-radius: 8px !important;
    font-size: 14px !important;
    outline: none !important;
}

.react-tel-input .form-control::placeholder {
    color: rgba(255, 255, 255, 0.5) !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
    border-radius: 8px 0 0 8px !important;
    background: transparent !important;
}

.react-tel-input .arrow {
    display: none !important;
}
